import React, {useEffect, useMemo, useState} from "react";
import {audio_types, SUPPORTED_PREVIEW_TYPES, video_types} from "../FileView";
import Plyr from "plyr-react";
import FadeInOut from "../../Miscellaneous/FadeInOut";
import "plyr-react/plyr.css";
import "./style/MediaViewer.css";
import {downloadBlob} from "./PDFViewer";
import {Spinner} from "./Spinner";

export const MediaViewer = ({file, addBlobUrl, getBlobUrl, password}) => {

    const [videoSrc, setVideoSrc] = useState(null)

    useEffect(() => {
        setVideoSrc(null)
        /*
        we keep blob urls for better switching between already previewed files
        if(videoSrc.startsWith("blob")) {
            // revoke last blob as it is no longer needed
            URL.revokeObjectURL(videoSrc)
        }
         */

        if(SUPPORTED_PREVIEW_TYPES.includes(file.type) && (video_types.includes(file.type) || audio_types.includes(file.type))) {
            if(file.encrypted) {
                downloadBlob(file.id, file.url, file.encrypted, file.salt, file.header, file.chunkSize, password, (blobUrl) => {
                    setVideoSrc(blobUrl)
                }, addBlobUrl, getBlobUrl)
            }
            else {
                setVideoSrc(file.url)
            }
        }
    }, [file]);

    const plyrSource = useMemo(
        () => ({ type: file == null ? null : ((video_types.includes(file.type)) ? "video" : "audio"),
            sources: [{ src: videoSrc == null ? null : videoSrc, type: file == null ? null : ((video_types.includes(file.type)) ? "video/" + file.type : "audio/" + file.type)}] }),
        [videoSrc, file]
    );
    const plyrOptions = useMemo(
        () => ({
            hideControls: true,
            fullscreen: { enabled: true, fallback: true, iosNative: true, container: null },
            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'pip', 'airplay', 'fullscreen'],
            keyboard: { focused: true, global: true },
            ads: { enabled: false, publisherId: '', tagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/22430538404/ysndit-plyr&description_url=[placeholder]&tfcd=0&npa=0&sz=400x300&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&correlator=[placeholder]&vpmute=0&vpa=click&vpos=preroll' }
        }),
        []
    );

    return(
        <>
        <FadeInOut show={videoSrc !== null && SUPPORTED_PREVIEW_TYPES.includes(file.type) && (video_types.includes(file.type) || audio_types.includes(file.type))} duration={200}>
            <Plyr key="player" source={plyrSource} options={plyrOptions}/>
        </FadeInOut>
            {(videoSrc == null && SUPPORTED_PREVIEW_TYPES.includes(file.type) && (video_types.includes(file.type) || audio_types.includes(file.type))) &&
                <Spinner />
            }
        </>
    )
}