import { useProvider } from "@adobe/react-spectrum";
import { MantineProvider } from "@mantine/core";
import { AuthPage, LoginPage } from "./Components/AuthPage/AuthPage";
import DownloadPage from './Components/DownloadPage/DownloadPage';

import { userContext } from "./Components/Auth/userContext";
import { useEffect, useState } from "react";
import User from "./Components/Auth/User";
import {ProfilePage} from "./Components/AuthPage/ProfilePage";
import {PricingPage} from "./Components/Billing/PricingPage";

export function App(props) {
    
    let { colorScheme } = useProvider();
    const [user, setUser] = useState(null);

    function getUserInfo() {
      var myHeaders = new Headers();
      if (process.env.NODE_ENV == 'development') {
        myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
        myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
      }
      myHeaders.append("Authorization", localStorage.getItem("token"));
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        credentials: 'include'
      };
  
      fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "user/info", requestOptions)
          .then(response => response.json())
          .then(data => {
            /*
            Set a stable browser id cookie (if third party cookies is disabled).
            */
            let browserId = window.localStorage.getItem('browser_id');
            if(browserId == undefined || browserId == null || browserId == "") {
              window.localStorage.setItem('browser_id', data["browser_id"]);
              browserId = data["browser_id"];
            }
            const user = new User(data["user_email"], data["user_name"], data["user_id"], data["authenticated"], browserId, data["default_download_domain"], data["all_download_domains"], data["adFreeDownloads"], data["avatar"], data["newsletter"], data["mobile"], data["stripe_client_secret"]);
            setUser(user);
          });
    }


useEffect(() => {
  getUserInfo();


  window.document.addEventListener("tokenUpdated", getUserInfo);
    return () => {
      // unsubscribe event
      window.document.removeEventListener("tokenUpdated", getUserInfo);
    };
}, [])


    let content
    if(window.location.pathname == "/auth" || window.location.pathname == "/auth/") {
        if(window.location.hostname == "ysendit.com" || window.location.hostname == "dev.ysendit.com") {
            content = (
                <AuthPage />
            );
        }
        else {
            content = (
                <AuthPage />)
        }
    }
    else if(window.location.pathname == "/profile" || window.location.pathname == "/profile/") {
        if(window.location.hostname == "ysendit.com" || window.location.hostname == "dev.ysendit.com") {
            content = (
                <ProfilePage />
            );
        }
        else {
            content = (
                <ProfilePage />)
        }
    }
    else if(window.location.pathname == "/pricing" || window.location.pathname == "/pricing/") {
        content = <PricingPage />
    }
    else {
        content = (<DownloadPage setUser={setUser} />)
    }

    return (
      <MantineProvider withGlobalStyles theme={{ colorScheme: ((colorScheme == 'dark') ? 'dark' : 'light'),
    colors: {
      blue: [
        "#E5F2FF",
        "#B8DBFF",
        "#8AC4FF",
        "#5CADFF",
        "#2E96FF",
        "#007FFF",
        "#0066CC",
        "#004C99",
        "#003366",
        "#001933"
      ],
      dark: [
        "#ebebeb",
        "#C1C2C5",
        "#A6A7AB",
        "#909296",
        "#5C5F66",
        "#373A40",
        "#2C2E33",
        "#25262B",
        "#1A1B1E",
        "#141517"
      ],
      accent: [
        "var(--spectrum-accent-background-color-default)",
        "var(--spectrum-accent-background-color-default)",
        "var(--spectrum-accent-background-color-default)",
        "var(--spectrum-accent-background-color-default)",
        "var(--spectrum-accent-background-color-default)",
        "var(--spectrum-accent-background-color-default)",
        "var(--spectrum-accent-background-color-default)",
        "var(--spectrum-accent-background-color-default)",
        "var(--spectrum-accent-background-color-default)",
        "var(--spectrum-accent-background-color-default)"

      ]
    }, primaryColor: 'blue' }}>
      <userContext.Provider value={user}>
{content}
</userContext.Provider>
      </MantineProvider>
      
    );
  }