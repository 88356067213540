import React, {useState, useEffect, useContext} from "react";

import { Anchor, Box, Center, Image, Paper, Space, Text, Title, rem } from "@mantine/core";
import FadeInOut from "../Miscellaneous/FadeInOut";
import ConsentModal from "../ConsentModal/components/ConsentModal";
import { IconArrowLeft } from "@tabler/icons-react";

import '../AuthPage/AuthPage.css'
import {userContext} from "../Auth/userContext";
import {ConsentHandler} from "../ConsentModal/components/Consent";


export function PricingPage(props) {

    const [logo, setLogo] = useState(null);
    const queryParameters = new URLSearchParams(window.location.search);
    const redirectUri = decodeURI(queryParameters.get("redirect_to"));

    const [publishableKey, setPublishableKey] = useState(null);
    const [pricingTableId, setPricingTableId] = useState(null);
    const [pricingEnabled, setPricingEnabled] = useState(false);

    function initSiteData(data) {
      setLogo(process.env.REACT_APP_YSNDIT_ENDPOINT + data.logo.substring(1));
      const title = document.getElementById("title");
      title.textContent = "Update profile | " + data.siteName;
      const description = document.getElementById("description");
      description.content = data.description;
      const favicon = document.getElementById("favicon");
      favicon.href = process.env.REACT_APP_YSNDIT_ENDPOINT + data.favicon.substring(1);
      const largerIcon = document.getElementById("larger_icon");
      largerIcon.href = process.env.REACT_APP_YSNDIT_ENDPOINT + data.logo.substring(1);
      setPricingTableId(data.stripe_pricing_table_id);
      setPublishableKey(data.stripe_publishable_key);
      setPricingEnabled(data.premium_purchasable);
    }

    const user = useContext(userContext);

    useEffect(() => {
        if(user != null && !user.authenticated && pricingEnabled) {
            if(queryParameters.has("widgetLogin")) {
                window.location.href = "/auth?widgetLogin&redirect_to=" + encodeURI(window.location.href);
            }
            else {
                window.location.href = "/auth?redirect_to=" + encodeURI(window.location.href);
            }

        }
    }, [user])


    ConsentHandler.appendScript("https://js.stripe.com/v3/pricing-table.js", {
        async: true
    }, "head");



    function goBack(success) {
        if(queryParameters.has("widgetLogin")) {
            window.location.href = queryParameters.get("redirect_to")
        }
        else {
            window.close();
            window.location.href = "/"
        }
      }


  return (
    <>
        <ConsentModal requestedPage="download" reactCallback={null} reactPreflight={initSiteData} />
    <div className="App">
             <div className="center" style={{maxWidth: "800px"}}>
   <FadeInOut show={logo != null}>
   <Image height={80} fit="contain" src={logo} />
   </FadeInOut>





      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Upgrade to Premium
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Not your account?{' '}
        <Anchor href={process.env.REACT_APP_YSNDIT_ENDPOINT + "user/logout?redirect=" + encodeURI(window.location.href)} size="sm">
          Logout
        </Anchor>
      </Text>


      <Paper style={{
          backgroundColor: '#cce6ff',
      }} pos="relative" shadow="md" p={30} mt={30} radius="md">
          {(pricingEnabled && user != null && publishableKey != null && pricingTableId != null && !user.adFree) &&
<stripe-pricing-table pricing-table-id={pricingTableId}
publishable-key={publishableKey} client-reference-id={user.id} customer-email={(user.stripe_client_secret) ? null : user.email} customer-session-client-secret={user.stripe_client_secret}>
</stripe-pricing-table>
          }
          {(pricingEnabled && user != null && user.adFree) &&
              <Text>
                  You are a Pro! Thank you for your upgrade to ysendit.com Premium.
              </Text>
          }
          {(!pricingEnabled) &&
              <Text>
                  An upgrade to Premium is currently not possible.
              </Text>
          }
      </Paper>
      <Space h="xl" />
      <Anchor onClick={() => {goBack(false);}} target="_blank">
      <Center pb={25}>
          {(user != null && (user.adFree || !pricingEnabled)) &&
              <>
              <IconArrowLeft size={rem(14)} />
              <Box ml={5}>Back to website</Box>
              </>
          }
          {(user != null && !user.adFree && pricingEnabled) &&
              <>
              <IconArrowLeft size={rem(14)} />
              <Box ml={5}>No, thanks</Box>
              </>
          }
      </Center>
    </Anchor>

      </div>
    </div>
    </>
  );
}
