import _sodium from 'libsodium-wrappers-sumo';
import File from "./File";

let sodium;
(async () => {
  await _sodium.ready;
  sodium = _sodium;
})();


export const Transfer_STATE = {

  // transfer not found
  NOT_FOUND: {
     showMessage: true,
     variant: "negative",
     message: "A transfer with this url wasn't found. Double check the url and try again"
  },

  // transfer is deleted
  DESTROYED: {
    showMessage: true,
    variant: "negative",
    message: "The transfer is removed. Contact sender to resend it"
 },

  // transfer is still being processed
  PROCESSING: {
    showMessage: true,
    variant: "info",
    message: "Please check back in a few moments. Files are still being uploaded"
  },

  // user has no right to view
  NOT_ALLOWED: {
    showMessage: true,
    variant: "negative",
    message: "You do not have access to the transfer. Probably because you have already downloaded it"
  },

  // user is required to enter password
  NOT_AUTHENTICATED: {
    showMessage: false,
    variant: "",
    message: ""
  },

  // download is showable
  OK: {
    showMessage: false,
    variant: "",
    message: ""
  },
};


export default class Transfer {
    constructor(upload_id, size=0, fileCount=0, dateAdded = 0, adFree = false, allowComments, expiration = 0, message, emailFrom = null, receiverMail, passwordProtected = false, enteredPassword="", authenticated = false, allowed=false, isUploader=false, onlyOnce = false, status = "ready", backgroundUrl = "", logoUrl = "", logoLink = "", transfer_name = "", e2e_encrypted, salt, nonce, sharePass, siteData=null) {
      this.upload_id = upload_id;
      this.size = parseInt(size);
      this.fileCount = fileCount;
      this.dateAdded = parseInt(dateAdded);
      this.adFree = adFree;
      this.expiration = parseInt(expiration);
      this.message = (!message) ? null : message.replaceAll("&&$$", "\n");
      this.emailFrom = emailFrom;
      this.receiverMail = (receiverMail) ? receiverMail: null;
      this.passwordProtected = passwordProtected;
      this.enteredPassword = enteredPassword;
      this.authenticated = authenticated;
      this.allowed = allowed;
      this.onlyOnce = (onlyOnce == "yes") ? true : false;
      this.status = status;
      this.backgroundUrl = backgroundUrl;
      this.logoUrl = logoUrl;
      this.logoLink = logoLink;
      this.isUploader = isUploader;
      this.transfer_name = transfer_name
      this.e2e_encrypted = (e2e_encrypted == "yes") ? true : false;
      this.salt = salt
      this.nonce = nonce
      this.sharePass = (sharePass == "yes") ? true : false;
      this.siteData = siteData;
      this.sodiumReady = false;
      if(allowComments == "yes") {
        this.allowComments = true;
      } else {
        this.allowComments = false;
      }
      if(this.status == null) {
        this.Transfer_STATE = Transfer_STATE.NOT_FOUND;
      }
      else if(this.status == "destroyed") {
        this.Transfer_STATE = Transfer_STATE.DESTROYED;
      }
      else if(this.status == "processing") {
        this.Transfer_STATE = Transfer_STATE.PROCESSING;
      }
      else if(this.status == "inactive" || !this.allowed) {
        this.Transfer_STATE = Transfer_STATE.NOT_ALLOWED;
      }
      else if(!this.authenticated) {
        this.Transfer_STATE = Transfer_STATE.NOT_AUTHENTICATED;
      }
      else {
        this.Transfer_STATE = Transfer_STATE.OK;
      }
  }

  async awaitSodiumReady() {
    if (!this.sodiumReady) {
      await _sodium.ready;
      this.sodiumReady = true;
    }
    sodium = _sodium;
  }

  async getKey(pass) {
    await this.awaitSodiumReady()
    const salt = sodium.from_base64(this.salt, sodium.base64_variants.URLSAFE_NO_PADDING)
    const key = sodium.crypto_pwhash(
        sodium.crypto_secretbox_KEYBYTES,
        pass,
        salt,
        sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE,
        sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE,
        sodium.crypto_pwhash_ALG_ARGON2ID13
    );
    return key
  }

  async getBackendPassword(pass) {
      if(this.dateAdded <= 1730890348) {
        return pass
      }
      else {
        const key = await this.getKey(pass)
        return sodium.to_base64(sodium.crypto_kdf_derive_from_key(sodium.crypto_secretbox_KEYBYTES, 1, "YSENDITB", key), sodium.base64_variants.URLSAFE_NO_PADDING)
      }
  }

  async decrypt(files) {
      if (this.nonce == null || this.nonce === "null" || this.enteredPassword === "" || !this.e2e_encrypted) {
        return files
      }

      await this.awaitSodiumReady()

    if(this.message !== "" && this.message) {
      const nonce = sodium.from_base64(this.nonce, sodium.base64_variants.URLSAFE_NO_PADDING)
      const message = sodium.from_base64(this.message, sodium.base64_variants.URLSAFE_NO_PADDING)
      this.message = new TextDecoder().decode(sodium.crypto_secretbox_open_easy(message, nonce, await this.getKey(this.enteredPassword)))
    }

    const returnFiles = []
    for (const file of files) {
      const key = sodium.crypto_pwhash(
          sodium.crypto_secretbox_KEYBYTES,
          this.enteredPassword,
          sodium.from_base64(file.salt, sodium.base64_variants.URLSAFE_NO_PADDING),
          sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE,
          sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE,
          sodium.crypto_pwhash_ALG_ARGON2ID13
      );
      const nonce = sodium.from_base64(file.nonce, sodium.base64_variants.URLSAFE_NO_PADDING)
      const name = sodium.from_base64(file.name, sodium.base64_variants.URLSAFE_NO_PADDING)
      file.name = new TextDecoder().decode(sodium.crypto_secretbox_open_easy(name, nonce, key))
      returnFiles.push(file)
    }

    return returnFiles;

  }
}