import './FileView.css';
import { Button} from "@adobe/react-spectrum";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import PDFViewer from "./PDFViewer/PDFViewer";
import { useHotkeys } from "react-hotkeys-hook";
import {MediaViewer} from "./PDFViewer/MediaViewer";



export const SUPPORTED_PREVIEW_TYPES = ["png", "jpeg", "jpg", "gif", "bmp", "pdf", "mp4", "webm", "ogg", "mp3", "wav"];

export const video_types = ["mp4", "ogg", "webm"];
export const audio_types = ["mp3", "wav", "ogg"];
export const photo_types = ["png", "jpeg", "jpg", "gif", "bmp"];

export const previewable = (file) => file.preview != null || (SUPPORTED_PREVIEW_TYPES.includes(file.type) && (!file.encrypted || file.size <= 250 * 1000 * 1000));


export const supportsPDFViewer = (file) => {
  return file.type == "pdf" || photo_types.includes(file.type) || file.preview != null;
}

export const supportsAnnotations = (file) => {
  return supportsPDFViewer(file);
}

export default function FileView({file, files, setFullscreen, setOpenFileKey, setSelectionPosition, setSelectionContent, areaSelection, hideSelection, highlights, password}) {
    

    const [localFullscreen, setLocalFullscreen] = useState(false);

    const [prevFilePreviewable, setPrevFilePreviewable] = useState(false);
    const [nextFilePreviewable, setNextFilePreviewable] = useState(false);

    const [blobUrls, setBlobUrls] = useState([])


    useHotkeys(['arrowLeft', 'ctrl#arrowLeft'], () => {
      if(prevFilePreviewable) {
        openPrevFile();
      }
    }, {combinationKey:"#"})

    useHotkeys(['arrowRight', 'ctrl#arrowRight'], () => {
      if(nextFilePreviewable) {
        openNextFile();
      }
    }, {combinationKey:"#"})

    const addBlobUrl = (id, url) => {
        const newArray = blobUrls
        newArray.push({url, id})
        setBlobUrls(newArray)
    }

    const getBlobUrl = (id) => {
        const entry = blobUrls.find((value) => value.id === id)
        if(entry !== undefined) {
            return entry.url
        }
        return undefined
    }
    

    useEffect(() => {
      if(file != null) {
        setNextFilePreviewable(checkNextFilePreviewable());
        setPrevFilePreviewable(checkPrevFilePreviewable());
        setOpenFileKey(null);
      }



    }, [file]);

    return(
        <>

        

{(files.length > 1) && (
 <div style={{position:"absolute", top:"50%", transform: "translateY(-50%)", width:"100%", zIndex:5}}>
<Button position="absolute" left="15px" isDisabled={!prevFilePreviewable} onPress={() => openPrevFile()} variant="secondary" style="fill" aria-label="Previous file"><ChevronLeft /></Button>
<Button position="absolute" right="15px" isDisabled={!nextFilePreviewable} onPress={() => openNextFile()} variant="secondary" style="fill" aria-label="Next file"><ChevronRight /></Button>
</div>

)}


        
        {(file != null && (file.preview != null || (SUPPORTED_PREVIEW_TYPES.includes(file.type) && !video_types.includes(file.type) && !audio_types.includes(file.type)))) && (

<PDFViewer password={file.password} id={file.id} addBlobUrl={addBlobUrl} getBlobUrl={getBlobUrl} toggleFullscreen={toggleFullscreen} src={(file.preview == null) ? file.url : file.preview} encrypted={file.encrypted} salt={file.salt} header={file.header} chunkSize={file.chunkSize} type={file.type} image={photo_types.includes(file.type)} highlights={highlights} hideSelection={hideSelection} areaSelection={areaSelection} setSelectionContent={setSelectionContent} setSelectionPosition={setSelectionPosition}  />
               )}
      {(file != null) && (

              <MediaViewer password={file.password} file={file} addBlobUrl={addBlobUrl} getBlobUrl={getBlobUrl} />

)}
</>
    );

    function openNextFile() {
      let firstFound = false;
      for(let key = file.keyInList + 1; key < files.length; key++) {
        if(previewable(files[key])) {
          if(!firstFound) {
            setPrevFilePreviewable(true);
            setOpenFileKey(key);
            firstFound = true;
          }
          else {
            setNextFilePreviewable(true);
            break;
          }
        }
      }
    }

    function openPrevFile() {
      let firstFound = false;
      for(let key = file.keyInList - 1; key >= 0; key--) {
        if(previewable(files[key])) {
          if(!firstFound) {
            setNextFilePreviewable(true);
            setOpenFileKey(key);
            firstFound = true;
          }
          else {
            setPrevFilePreviewable(true);
            break;
          }
        }
      }
    }

    function checkNextFilePreviewable() {
      for(let key = file.keyInList + 1; key < files.length; key++) {
        if(previewable(files[key])) {
          return true;
        }
      }
    }

    function checkPrevFilePreviewable() {
      for(let key = file.keyInList - 1; key >= 0; key--) {
        if(previewable(files[key])) {
          return true;
        }
      }
    }

    function toggleFullscreen() {
        setFullscreen(!localFullscreen);
        setLocalFullscreen(!localFullscreen);
    }
}