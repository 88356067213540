export default class File {
    constructor(key, id, name, url, type, size = 0, keyInList, preview = null, salt, header, password, chunkSize) {
    this.key = key;
    this.id = id;
    this.password = password;
    this.name = name;
    if(!chunkSize) {
        this.chunkSize = 5 * 1024 * 1024
    }
    else {
        this.chunkSize = parseInt(chunkSize)
    }

      this.url = url;
      this.type = (type != null) ? type.toLowerCase() : null;
      this.size = parseInt(size);
      this.keyInList = keyInList;
      this.preview = preview;
      this.salt = salt
        this.header = header
        this.encrypted = (salt !== "" && salt !== null && salt !== undefined)
    }
}