import {
    ActionButton,
    Badge,
    Button, Content,
    Dialog, DialogTrigger,
    Divider,
    Flex,
    Heading,
    Item,
    Link,
    ListView,
    StatusLight,
    Text,
    View,
    Well
} from '@adobe/react-spectrum';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import prettyBytes from 'pretty-bytes';
import Icon from "../../assets/img/icon.png"
import KeyClock from '@spectrum-icons/workflow/KeyClock';
import Settings from '@spectrum-icons/workflow/Settings';

import { FileItem } from './FileItem';
import DownloadButton from './DownloadButton';
import Truncate from 'react-truncate';
import TransferDetails from './TransferDetails';
import { Transfer_STATE } from './Transfer';
import ManageButton from './ManageButton';

import Ad from '../Miscellaneous/Ad';
import AppFooter from './AppFooter';
import LockClosed from "@spectrum-icons/workflow/LockClosed";


export function MainRow({ transfer, file, setSelectFiles, showFiles, files, selectedFiles, setFileListOpen, isFileListOpen, privateId, setIsDeleteModalOpen, isFullscreen }) {

    return(
        <>
   <View>

{(!isFileListOpen && transfer.fileCount > 0) && (
   <ListView 
   UNSAFE_style={{"borderWidth": "0px", "borderRadius": "8px"}}
   selectionMode="none"
   selectedKeys="all"
   selectionStyle="highlight"
   aria-label="ListView example with complex items"
 >
 
 
     <Item key="1">
     <FileItem file={file}/>
     </Item>
 
 </ListView>
)}

{(transfer.siteData.slot_MainRow != "" && !transfer.adFree) && (
    <Ad slot={transfer.siteData.slot_MainRow} key="ad_MainRow" style={{height: "130px", display:(!isFullscreen) ? "block" : "none !important"}} googleAdId={transfer.siteData.googleAdId} format="fluid" layoutKey="-h2+d+5c-9-3e" alternateLink={transfer.siteData.alternateAdLink}>
        {(transfer.siteData.alternateAdImage != "") && (
            <img style={{maxHeight: "115px", maxWidth:"320px"}} src={transfer.siteData.alternateAdImage}/>
        )}
    </Ad>
    )}
       {(!isFileListOpen && transfer.fileCount > 1) && (
           <View padding="6px" borderColor="gray-300" borderTopWidth="thin" borderBottomWidth="thin">
               <Flex direction="column" alignItems="center">
                   <Link onPress={() => setFileListOpen(true)}>
  <a>Show {transfer.fileCount - 1} hidden {((transfer.fileCount - 1) <= 1) ? "file" : "files" }</a>
</Link>
</Flex>
</View>
)}
{(isFileListOpen || (!isFileListOpen && transfer.fileCount == 1)) && (
<Divider size='S' />
)}
<View padding="10px">
<Flex direction="row" wrap gap="size-150" alignItems="center" justifyContent="space-between">
    <Flex direction="row" wrap gap="size-150" alignItems="center" justifyContent="space-between">
    <a href={(transfer.logoLink != "" && transfer.logoLink != null) ? transfer.logoLink : "/"} target="_blank">
    <img height="50px" style={{maxWidth: "100px"}} objectFit="contain"
      src={(transfer.logoUrl != "") ? transfer.logoUrl : process.env.REACT_APP_YSNDIT_ENDPOINT + transfer.siteData.siteLogo.substring(1)}/>
    </a>
  <View alignSelf="center" maxWidth={430}>
  <Heading margin="0">{(transfer.transfer_name != "" && transfer.transfer_name) ? transfer.transfer_name : ("Transfer " + transfer.upload_id)}</Heading>
  {(transfer.Transfer_STATE == Transfer_STATE.OK && transfer.fileCount > 0) && (
   <TransferDetails transfer={transfer}>
   <Link isQuiet ><a> {transfer.fileCount} {(transfer.fileCount > 1) ? "files" : "file"} ({prettyBytes(transfer.size)})
   <InfoOutline size="XS" UNSAFE_style={{"paddingLeft":"2px"}} /></a></Link>
   </TransferDetails>
  )}
  </View>
    </Flex>
    <Flex direction="row" wrap gap="size-150" alignItems="center" justifyContent="space-between">
  {transfer.Transfer_STATE == Transfer_STATE.OK && (
  <View alignSelf="center">
  <DownloadButton adFree={transfer.adFree} setSelectFiles={setSelectFiles} selectedFiles={selectedFiles} files={files} transferId={transfer.upload_id} isDownloadable={(transfer.Transfer_STATE == Transfer_STATE.OK && transfer.fileCount > 0)} setFileListOpen={setFileListOpen} />
  </View>
  )}
   {(transfer.Transfer_STATE == Transfer_STATE.OK && transfer.isUploader) && (
  <ManageButton alignSelf="center" transfer={transfer} privateId={privateId} setIsDeleteModalOpen={setIsDeleteModalOpen} />
   )}
    </Flex>
  {transfer.Transfer_STATE != Transfer_STATE.OK && (
   <StatusLight variant={transfer.Transfer_STATE.variant}>
    {transfer.Transfer_STATE.message}
    </StatusLight>
  )}


  
  
</Flex>

{transfer.onlyOnce && (
<Badge variant="negative">
  <KeyClock />
  <Text>You can view this transfer only once</Text>
</Badge>
)}
    {transfer.e2e_encrypted && (
        <DialogTrigger mobileType="tray" type="popover" hideArrow>
            <Link isQuiet>
                <a>
                <Badge variant="positive">
                    <LockClosed />
                    <Text>Transfer is end-to-end encrypted</Text>
                </Badge>
                </a>
            </Link>

            <Dialog>
                <Heading>End-to-end Encryption</Heading>
                <Divider />
                <Content>
                    <Text>
                        The files and the message of this transfer were encrypted before being uploaded. <br/>
                        Decryption happens locally at your device. Nobody else can derive the content of this transfer.

                        <br/>
                        <br/>
                        <b>Security properties</b>
                        <ul style={{paddingLeft: "15px"}}>
                            <li>Only recipients can derive the content of this transfer</li>
                            <li>Guarantee that files & transfer message were not truncated, reordered or modified after
                                upload
                            </li>
                        </ul>
                        <Link>
                            <a href="https://help.ysendit.com/end-to-end-encryption" target="_blank">Learn more about E2E
                                Encryption</a>
                        </Link>
                    </Text>
                </Content>
            </Dialog>
        </DialogTrigger>
    )}
{transfer.message != null && (
<Well marginTop="10px">
<Truncate lines={(transfer.fileCount > 0) ? 1 : 5} ellipsis={<Text>... <TransferDetails transfer={transfer}><Link>Read on</Link></TransferDetails></Text>}>
                {transfer.message}
            </Truncate>
</Well>
)}
<View isHidden={{ base: false, M: true }} marginTop="20px">
<AppFooter imprint={transfer.siteData.siteImprint} terms={transfer.siteData.siteTerms} privacy={transfer.siteData.sitePrivacy} help={transfer.siteData.help_url} />
</View>
</View>
</View>     
</>
    );
   
}

 // Set default props
 MainRow.defaultProps = {
  transfer: {
    id: "undefined",
    fileCount: 0,
    expiry: "undefined",
    size: "0 KB"
  },
  file: "Button Text",
  abc: "sss"
};

