import { ActionGroup, Item, Text } from '@adobe/react-spectrum';
import PrintPreview from '@spectrum-icons/workflow/PrintPreview';
import Download from '@spectrum-icons/workflow/Download';
import prettyBytes from 'pretty-bytes';
import { FileIcon, defaultStyles } from 'react-file-icon';
import posthog from 'posthog-js';
import {previewable as isPreviewable} from "../DownloadPage/FileView";

import './FileItem.css';

import { SUPPORTED_PREVIEW_TYPES } from '../DownloadPage/FileView';
import {getDownloadUrl} from "./DownloadButton";

export function FileItem({file, filePreviewHandler, setSelectedKeys, setOpenedFileKey, itemKey, selection, transferId, totalFiles}) {

  const previewable = isPreviewable(file);
  const disabledActions = [];

  
  if(!previewable) {
    disabledActions.push("preview");
  }

  async function handleAction(key) {
    if (key == "download") {
      const downloadUrl = await getDownloadUrl(transferId, [file], file.password)
      window.open(downloadUrl,"_self");
      posthog.capture('download started', {uploadID: transferId, filesDownloaded: 1, totalFiles: totalFiles});
    } else {
      if (!selection) {
        const keys = new Set();
        keys.add(itemKey.toString());
        setSelectedKeys(keys);
        setOpenedFileKey(keys);
      }
      filePreviewHandler(file, true);
    }
  }

    return(
      <>
        {(file != null) &&
    <Text>
    <div className='fileExtensionIcon'>

    <FileIcon extension={file.type} {...defaultStyles[file.type]} />

      </div>
    </Text>
}
{(file != null) &&
    <Text position="relative" left="35px" UNSAFE_style={{"paddingRight": "35px"}}>{file.name}</Text>
  }
  {(file != null) &&
    <Text slot="description" position="relative" left="35px">{prettyBytes(file.size)}</Text>
  }
    <ActionGroup overflowMode="collapse"
  buttonLabelBehavior="collapse"
  onAction={handleAction}
  disabledKeys={disabledActions}>
      <Item key="preview" textValue="Preview File">
      <PrintPreview />
        
      </Item>
      <Item key="download" textValue="Download File">
        <Download />
        
      </Item>
    </ActionGroup>

    </>
    );
   
}

