import React, { useState, useEffect, useCallback } from "react";
import { Button, Flex, Item, Menu, MenuTrigger } from '@adobe/react-spectrum';
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";
import DownZip from 'downzip';
import posthog from 'posthog-js';
import KeepOpenToast from "./KeepOpenToast";
import { useHotkeys } from "react-hotkeys-hook";
import { DownloadAdPopup } from "../DownloadAdPopup/DownloadAdPopup";

export async function getDownloadUrl(transferId, files, password) {
    const downZip = new DownZip();
    await downZip.register();

    // Initialize download
    const downloadId = "";
    const zipFileName = "ysndit-" + transferId;
    let filesToDownload = [];
    let tempFiles = files;
    let downloadUrl = null;
    if(tempFiles != null && tempFiles.length > 0) {
        filesToDownload = tempFiles.map((file) => ({name: file.name, downloadUrl: file.url, size: file.size, salt: file.salt, header: file.header, password, chunkSize: file.chunkSize}));
        downloadUrl = await downZip.downzip(
            downloadId,
            zipFileName,
            filesToDownload
        );
    }
    return downloadUrl
}

export default function DownloadButton({adFree, setSelectFiles, selectedFiles, files, transferId, isDownloadable, setFileListOpen}) {
    const defaultMode = "downloadAll";
    const [selectedMode, setSelectedMode] = useState([defaultMode]);

    const [downloadCount, setDownloadCount] = useState(0); 

    useHotkeys(['alt', 'ctrl#s'], () => {
      let set = new Set();
        if([...selectedMode] == "downloadSelection") {
          set.add("downloadAll");
        }
        else {
          set.add("downloadSelection");
        }
        changeDownloadMode(set);
    }, {combinationKey:"#"})

    useHotkeys(['enter', 'ctrl#d'], () => {
      setDownloadCount(downloadCount + 1)
    }, {combinationKey:"#"})
    
    function changeDownloadMode(mode) {
        setSelectedMode(mode);
        setSelectFiles([...mode] == "downloadSelection");
        if([...mode] == "downloadSelection") {
            setFileListOpen(true);
        }
    }

    useEffect(() => {
        // React advises to declare the async function directly inside useEffect
        async function download() {
          // Setup downzip object

        let tempFiles = selectedFiles;
        if([...selectedMode] == "downloadAll") {
            tempFiles = files;
        }

        let downloadUrl = await getDownloadUrl(transferId, tempFiles, tempFiles[0].password);
        
        
        if(downloadCount != 0) {
            window.open(downloadUrl,"_self");
        }
        }
    
        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        
        if(downloadCount > 0) {
            download();
            posthog.capture('download started', {uploadID: transferId, filesDownloaded: (selectedFiles != null) ? selectedFiles.length : 0, totalFiles: (files != null) ? files.length : 0});
        }
      }, [downloadCount]);

    

    return (
       <>
       <KeepOpenToast notify={downloadCount } />
       {(!adFree && 1 == 0) &&
       <DownloadAdPopup downloadStarted={downloadCount} />
      }
       <Flex gap="size-75" justifyContent="end">
 {([...selectedMode] == "downloadSelection") &&
 <Button variant="accent" autoFocus isDisabled={!isDownloadable || selectedFiles == null || selectedFiles.length <= 0} onPress={() => setDownloadCount((c) => c + 1)}>Download {(selectedFiles != null) ? selectedFiles.length : "0"} file{((selectedFiles != null && selectedFiles.length != 1) ? "s" : "")}</Button>
}
{([...selectedMode] == "downloadAll") &&
<Button variant="accent" autoFocus isDisabled={!isDownloadable} onPress={() => setDownloadCount((c) => c + 1)}>Download all</Button>
}
<MenuTrigger>
<Button variant="accent" width="size-400" isDisabled={!isDownloadable}><ChevronDown /></Button>
        <Menu
          selectionMode="single"
          selectedKeys={selectedMode}
          onSelectionChange={changeDownloadMode}
          disabledKeys={(files != null && files.length == 1) ? ["downloadSelection"] : []}
          disallowEmptySelection
        >
          <Item key="downloadAll">Download all</Item>
          <Item key="downloadSelection">Select files</Item>
        </Menu>
      </MenuTrigger>
      </Flex>     
       </>
    );
   
}