export default class User {
    constructor(email, name, id, authenticated, browserId, defaultDownloadDomain, allDownloadDomains, adFree, avatar, newsletter, mobile, stripe_client_secret) {
    this.email = email;
    this.name = name;
    this.id = id;
    this.authenticated = authenticated;
    this.browserId = browserId;
    this.defaultDownloadDomain = defaultDownloadDomain;
    this.allDownloadDomains = allDownloadDomains;
    this.avatar = (avatar == "null") ? null : avatar;
    this.newsletter = (newsletter == "false") ? false : true;
    this.mobile = mobile;
    this.adFree = (adFree == "false") ? false : true;
    this.stripe_client_secret = stripe_client_secret;
    }
}